import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import Layout from '../components/Layout'

export default function BlogTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { site, markdownRemark } = data // data.markdownRemark holds your post data
  const { siteMetadata } = site
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <main>
        <Helmet>
          <title>{`${frontmatter.title} | ${siteMetadata.title}`}</title>
          <meta name="description" content={frontmatter.metaDescription} />
        </Helmet>
        <BlogPostContainerStyled>
          <article>
            {!frontmatter.thumbnail && (
              <ThumbnailStyled>
                <BlogPostTitleAndDate>
                  <h1>{frontmatter.title}</h1>
                  <div>{frontmatter.date}</div>
                </BlogPostTitleAndDate>
              </ThumbnailStyled>
            )}
            {!!frontmatter.thumbnail && (
              <ThumbnailStyled
                style={{ backgroundImage: `url(${frontmatter.thumbnail})` }}
              >
                <BlogPostTitleAndDate>
                  <h1>{frontmatter.title}</h1>
                  <div>{frontmatter.date}</div>
                </BlogPostTitleAndDate>
              </ThumbnailStyled>
            )}
            <BlogPostContent dangerouslySetInnerHTML={{ __html: html }} />
          </article>
        </BlogPostContainerStyled>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
      }
    }
  }
`

const ThumbnailStyled = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  background-color: var(--secondary-darkgray);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--primary-light);
  margin-bottom: 36px;
  max-width: 100%;
  min-height: 380px;
  position: relative;
  text-align: center;

  &:before {
    content: '';
    background: rgba (0, 0, 0, 0.4);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }
`
const BlogPostContainerStyled = styled.div`
  color: var(--primary-darkgray);
`
const BlogPostContent = styled.div`
  margin: 0 auto;
  max-width: 768px;

  li {
    margin-left: 24px;

    :last-child {
      margin-bottom: 24px;
    }
  }
`
const BlogPostTitleAndDate = styled.div`
  background: var(--secondary-darkgray);
  padding: 8px 20px;

  h1 {
    color: var(--primary-light);
    font-size: 48px;
    line-height: 1.5;
    margin: 0.5rem 0;
    text-align: center;
    word-break: break-word;
  }
`
